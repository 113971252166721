/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import {
  IExistentFileModalData,
  IFile,
  IFileResponse,
  IFileSearchResponse,
  IFolder,
} from "@models/files/File.type";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  applyFileOrderFilters,
  deleteFiles,
  getExistentFilesValuesByFolder,
  getFiles,
  getFilesWithQuery,
  getFoldersId,
  getLastEditedFiles,
  getVersionsHistory,
  postFolder,
  restoreVersion,
  updateElementName,
} from "@redux/files/thunks/fileThunk";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { IDocument } from "@models/document/NewDocument.type";
import { IRow } from "@models/Table.type";
import { IVault } from "@redux/vaults/types";
import _ from "lodash";
import { createFolderFromDocumentsV2 } from "@redux/documents/thunks/documentsThunk";
import { getCookie } from "@api/AdminHttpProvider";
import { TTag } from "@models/Tag.type";

interface IFileSlice {
  pending: boolean;
  data: IFileResponse;
  pendingPost: boolean;
  pendingDelete: boolean;
  foldersPending: boolean;
  selectedFiles: IFile[];
  downloading: boolean;
  // rename
  elementToRename: IFile | null; // Active file to be renamed
  // folders
  foldersId: number[];
  parents: any[];
  // last edited (dashboard)
  lastEdited: IFile[];
  copiedFile?: IFile | null;
  // drag and drop
  dragElement: IFile | null; // Element is being dragged
  dropElementId: number | null; // Id of the element (dropzone)
  draggingId: number;
  orderFilter: number | null;
  orderFilterName: string | null;
  folderFilters: string[];
  elementToTag: IFile | null; // Active file to be tagged
  search: IFileSearchResponse & {
    pending: boolean;
    show: boolean;
    query: string;
    filter: string;
  };
  // existent files
  existentFilesValues: Array<boolean>;
  showDragDropExistentFileModal: boolean;
  existentFileModalData: IExistentFileModalData;
  history: IHistoryData;
}

const initialFileResponseProperties: IFileSearchResponse = {
  data: [],
  links: [],
  meta: {},
};

const initialState: IFileSlice = {
  pending: false,
  data: {
    id: 0,
    name: "",
    parent_id: 0,
    parent: null,
    parents: [],
    children: [],
  },
  pendingPost: false,
  pendingDelete: false,
  foldersPending: true,
  selectedFiles: [],
  foldersId: [],
  parents: [],
  downloading: false,
  // rename
  elementToRename: null,
  // last edited (dashboard)
  lastEdited: [],
  copiedFile: null,
  // drag and drop
  dragElement: null,
  dropElementId: null,
  draggingId: -1,
  orderFilter: 1,
  orderFilterName: "Newest",
  folderFilters: [],
  // element to tag
  elementToTag: null,
  search: {
    ...initialFileResponseProperties,
    pending: false,
    show: false,
    query: "",
    filter: "",
  },
  // existent files
  existentFilesValues: [],
  showDragDropExistentFileModal: false,
  existentFileModalData: {},
  history: {
    data: [],
    pending: false,
  },
};

interface IHistoryData {
  data: IFile[];
  pending: boolean;
}

export const filesApi = createApi({
  reducerPath: "filesApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BACK_BASE_URL }),
  endpoints: (builder) => ({
    getAllFiles: builder.query<void, void>({
      query: () => {
        const token =
          getCookie("access_token") || localStorage.getItem("access_token");
        return {
          url: "api/admin/documents/null",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": `application/json`,
          },
        };
      },
      transformResponse: (response: any) => response.data.children,
    }),
    getFileById: builder.query<void, number | null>({
      query: (id) => {
        const token = getCookie("access_token");
        return {
          url: `api/admin/documents/${id}`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": `application/json`,
          },
        };
      },
      transformResponse: (response: any) => response.data,
    }),
  }),
});

export const { useGetAllFilesQuery, useGetFileByIdQuery } = filesApi;

export const fileSlice = createSlice({
  name: "file",
  initialState,
  reducers: {
    setDownloading: (state, action: PayloadAction<boolean>) => {
      state.downloading = action.payload;
    },
    setFilters: (state, action: PayloadAction<string[]>) => {
      state.folderFilters = action.payload;
    },
    toggleSelectedFiles: (state: any, action: PayloadAction<IFile>) => {
      const file = action.payload;
      state.selectedFiles = state.selectedFiles.some((selectedFile: IFile) =>
        _.isEqual(selectedFile.id, file.id),
      )
        ? state.selectedFiles.filter(
            (prevFile: IFile) => !_.isEqual(prevFile.id, file.id),
          )
        : [...state.selectedFiles, file];
    },
    setCopiedFile: (state: any, action: PayloadAction<IFile>) => {
      const file = action.payload;
      state.copiedFile = file;
    },
    pasteFileReducer: (state: any, action: PayloadAction<IFile>) => {
      const file = action.payload;
      state.copiedFile = null;
      state.data.children.push(file);
    },
    addFile: (state: any, action: PayloadAction<IFile>) => {
      const file = action.payload;
      state.data.children.push(file);
      if (!file.type) {
        state.foldersId.push(file.id);
      }
    },
    setPending: (state: any, action: PayloadAction<boolean>) => {
      const pending = action.payload;
      state.pending = pending;
    },
    setElementToRename: (
      state: IFileSlice,
      action: PayloadAction<IFile | null>,
    ) => {
      state.elementToRename = action.payload;
    },
    renameElement: (state: IFileSlice, action: PayloadAction<IFile | null>) => {
      const file = action.payload;
      state.data.children = state.data.children.map((o: IFile) => {
        if (_.isEqual(o.id, file?.id))
          return { ...o, name: file?.name || o.name };
        return o;
      });
    },
    setDragElement: (
      state: IFileSlice,
      action: PayloadAction<IFile | null>,
    ) => {
      state.dragElement = action.payload;
    },
    setDropElementId: (
      state: IFileSlice,
      action: PayloadAction<number | null>,
    ) => {
      state.dropElementId = action.payload;
    },
    setCurrentHierarchy: (
      state: IFileSlice,
      action: PayloadAction<IFile | null>,
    ) => {
      const file = action.payload;
      if (!_.isNull(file))
        state.data.children = state.data.children.filter(
          (o: IFile) => !_.isEqual(o.id, file.id),
        );
    },
    setDraggingId: (state: IFileSlice, action: PayloadAction<number>) => {
      state.draggingId = action.payload;
    },
    setFileOrderFilters: (state, action: PayloadAction<any>) => {
      const { orderingId, label } = action.payload;
      state.orderFilter = orderingId;
      state.orderFilterName = label;
    },
    setElementToTag: (
      state: IFileSlice,
      action: PayloadAction<IFile | null>,
    ) => {
      state.elementToTag = action.payload;
    },
    setQuery: (state, action: PayloadAction<any>) => {
      state.search.query = action.payload;
    },
    setFilter: (state, action: PayloadAction<any>) => {
      state.search.filter = action.payload;
    },
    setShowDragDropExistentFileModal: (
      state: IFileSlice,
      action: PayloadAction<boolean>,
    ) => {
      state.showDragDropExistentFileModal = action.payload;
    },
    setDragDropExistentFileData: (
      state: IFileSlice,
      action: PayloadAction<IExistentFileModalData>,
    ) => {
      state.existentFileModalData = action.payload;
    },
    resetSearch: (state: IFileSlice, action: PayloadAction<void>) => {
      state.search = { ...initialState.search, filter: state.search.filter };
    },
    setVaultFolder: (
      state,
      action: PayloadAction<{ id: number; vault: IVault }>,
    ) => {
      const folderId = action.payload.id;
      const { vault } = action.payload;
      state.data = {
        ...state.data,
        children: state.data.children.map((item) => {
          if (folderId === item.id) {
            return {
              ...item,
              vaults: item.vaults.find((v: IVault) => v.id === vault.id)
                ? item.vaults.filter((v: IVault) => v.id !== vault.id)
                : item.vaults.concat(vault),
            };
          }
          return item;
        }),
      };
    },
    removeChildren: (state, action: PayloadAction<{ id: number }>) => {
      const childrenId = action.payload.id;
      state.data = {
        ...state.data,
        children: state.data.children.filter(
          (child) => child.id !== childrenId,
        ),
      };
    },
    addFolder: (state, action) => {
      const folder = {
        ...action.payload,
        isDisabled: true,
        vaults: [],
      };
      state.data.children.push(folder);
    },
    enabledAllFolders: (state) => {
      const folders = state.data.children.map((fold) => {
        fold.isDisabled = false;
        return fold;
      });
      state.data.children = folders;
    },
    disabledFiles: (state, action: PayloadAction<{ filesId: Set<number> }>) => {
      const { filesId } = action.payload;
      const files = state.data.children.map((file) => {
        if (!filesId.has(file.id)) {
          return {
            ...file,
            isDisabled: true,
          };
        }
        return {
          ...file,
        };
      });
      state.data.children = files;
    },
    updateDocumentTags: (
      state,
      action: PayloadAction<{ id: number; tags: TTag[] }>,
    ) => {
      const { id, tags } = action.payload;
      state.data.children = state.data.children.map((child) => {
        if (child.id === id) {
          return {
            ...child,
            tags,
          };
        }
        return child;
      });
    },
    restartSelectedFiles: (state) => {
      state.selectedFiles = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getFiles.fulfilled,
      (state, action: PayloadAction<{ data: IFileResponse }>) => {
        const { data } = action.payload;
        const { orderFilter, orderFilterName } = initialState;
        state.data = data;
        state.pending = false;
        state.orderFilter = orderFilter;
        state.orderFilterName = orderFilterName;
      },
    );
    builder.addCase(getFiles.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(getFiles.pending, (state) => {
      const { selectedFiles } = initialState;
      state.pending = true;
      state.selectedFiles = selectedFiles;
    });
    builder.addCase(
      getFoldersId.fulfilled,
      (state, action: PayloadAction<number[]>) => {
        const data = action.payload;
        state.foldersId = data;
        state.foldersPending = false;
      },
    );
    builder.addCase(getFoldersId.rejected, (state) => {
      state.foldersPending = false;
    });
    builder.addCase(getFoldersId.pending, (state) => {
      state.foldersPending = true;
    });
    builder.addCase(
      postFolder.fulfilled,
      (state, action: PayloadAction<IFolder>) => {
        const folder = action.payload;
        state.pendingPost = false;
        state.data.children.push(folder);
        state.foldersId.push(folder.id);
      },
    );
    builder.addCase(
      createFolderFromDocumentsV2.fulfilled,
      (state, action: PayloadAction<IDocument>) => {
        state.foldersId.push(action.payload.id || 0);
        state.pending = false;
      },
    );
    builder.addCase(postFolder.rejected, (state) => {
      state.pendingPost = false;
    });
    builder.addCase(postFolder.pending, (state) => {
      state.pendingPost = true;
    });
    builder.addCase(
      deleteFiles.fulfilled,
      (state, action: PayloadAction<IFile[] | IRow[] | any[]>) => {
        const deletedFiles = action.payload;
        state.pendingDelete = false;
        state.selectedFiles = state.selectedFiles.filter(
          (selectedFile) =>
            !deletedFiles.find((deletedFile) =>
              _.isEqual(deletedFile.id, selectedFile.id),
            ),
        );
        state.data.children = state.data.children.filter(
          (savedFile) =>
            !deletedFiles.find((deletedFile) =>
              _.isEqual(deletedFile.id, savedFile.id),
            ),
        );
      },
    );
    builder.addCase(deleteFiles.rejected, (state, action) => {
      state.pendingDelete = false;
    });
    builder.addCase(deleteFiles.pending, (state) => {
      state.pendingDelete = true;
    });
    builder.addCase(
      updateElementName.fulfilled,
      (state: IFileSlice, action: PayloadAction<{ data: IFile }>) => {
        const { data: file } = action.payload;
        state.data.children = state.data.children.map((o: IFile) => {
          if (_.isEqual(o.id, file?.id)) return { ...o, name: file?.name };
          return o;
        });
      },
    );
    builder.addCase(
      applyFileOrderFilters.fulfilled,
      (state, action: PayloadAction<{ data: File[] | any }>) => {
        const { data } = action.payload;
        state.data.children = data.children;
        state.pending = false;
      },
    );
    builder.addCase(applyFileOrderFilters.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(applyFileOrderFilters.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(
      getLastEditedFiles.fulfilled,
      (state, action: PayloadAction<{ data: IFile[] }>) => {
        const { data } = action.payload;
        state.lastEdited = data;
        state.pending = false;
      },
    );
    builder.addCase(getLastEditedFiles.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(getLastEditedFiles.pending, (state) => {
      state.pending = true;
    });
    // getFilesWithQuery
    builder.addCase(
      getFilesWithQuery.fulfilled,
      (
        state,
        action: PayloadAction<{
          response: IFileSearchResponse;
          requestQuery: string;
        }>,
      ) => {
        const { response, requestQuery } = action.payload;
        const { data, links, meta } = response;
        const { show, query, filter } = state.search;
        let pending = true;
        if (_.isEqual(requestQuery, query)) pending = false;
        state.search = { data, links, meta, pending, show, query, filter };
      },
    );
    builder.addCase(getFilesWithQuery.pending, (state) => {
      state.search.pending = true;
    });
    builder.addCase(
      getExistentFilesValuesByFolder.fulfilled,
      (state, action: PayloadAction<{ data: IFile[] }>) => {
        state.existentFilesValues = [true, true, false];
      },
    );
    builder.addCase(
      getExistentFilesValuesByFolder.rejected,
      (state, action) => {},
    );
    builder.addCase(getExistentFilesValuesByFolder.pending, (state) => {
      state.history.pending = true;
    });
    builder.addCase(
      getVersionsHistory.fulfilled,
      (state, action: PayloadAction<{ data: IFile[] }>) => {
        state.history.data = action.payload.data;
        state.history.pending = false;
      },
    );
    builder.addCase(getVersionsHistory.pending, (state) => {
      state.history.pending = true;
    });
    builder.addCase(getVersionsHistory.rejected, (state) => {
      state.history.pending = false;
    });
    builder.addCase(restoreVersion.fulfilled, (state) => {
      state.history = initialState.history;
    });
  },
});

export const {
  toggleSelectedFiles,
  restartSelectedFiles,
  setPending,
  setElementToRename,
  setFilters,
  setVaultFolder,
  renameElement,
  addFile,
  setCopiedFile,
  setCurrentHierarchy,
  setDragElement,
  setDropElementId,
  setDraggingId,
  setFileOrderFilters,
  pasteFileReducer,
  setElementToTag,
  setQuery,
  setFilter,
  resetSearch,
  setDownloading,
  setShowDragDropExistentFileModal,
  setDragDropExistentFileData,
  removeChildren,
  addFolder,
  enabledAllFolders,
  disabledFiles,
  updateDocumentTags,
} = fileSlice.actions;
export default fileSlice.reducer;
