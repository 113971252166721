import React, { useEffect, useMemo } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import HeaderModule from "@components/HeaderModule";

import useAppSelector from "@hooks/useAppSelector";
import useAppDispatch from "@hooks/useAppDispatch";

import {
  setSelectedProject,
  setSelectedStore,
  setSelectedProcess,
  setProjectProcessId,
  setProjectProcessSteps,
} from "@redux/my-stores/slice";
import { threeDotsText } from "@helpers/threeDotsText";
import {
  useGetProcesses,
  useGetProjects,
  useGetStores,
} from "@redux/my-stores/api";
import { IMyStore, IProject } from "@redux/my-stores/types";
import { IProcess } from "@redux/approval-process/slices/processSlice";

export type OutletContext = {
  stores: {
    data: IMyStore[] | undefined;
    isLoading: boolean;
  };
  projects: {
    data: IProject[] | undefined;
    isLoading: boolean;
  };
  processes: {
    data: { id: number; process: IProcess; project: IProject }[] | undefined;
    isLoading: boolean;
  };
  refetchProcesses: () => void;
};

const StoresLayout: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();

  const { data: user } = useAppSelector((state) => state.user);
  const { selectedStore, selectedProject } = useAppSelector(
    (state) => state.myStores,
  );

  const {
    data: stores,
    isLoading: loadingStores,
    isFetching: isFetchingStores,
  } = useGetStores({ user });
  const {
    data: projects,
    isLoading: loadingProjects,
    isFetching: isFetchingProjects,
  } = useGetProjects(selectedStore?.id.toString() || "", {
    skip: !selectedStore?.id,
  });
  const {
    data: processes,
    isLoading: loadingProcesses,
    refetch,
  } = useGetProcesses(selectedProject?.id.toString() || "", {
    skip: !selectedProject?.id,
  });

  const context: OutletContext = {
    stores: {
      data: stores,
      isLoading: loadingStores || isFetchingStores,
    },
    projects: {
      data: projects,
      isLoading: loadingProjects || !selectedStore || isFetchingProjects,
    },
    processes: {
      data: processes,
      isLoading: loadingProcesses || !selectedProject || !selectedStore,
    },
    refetchProcesses: refetch,
  };

  // TODO: evitar saltos del title, mostrar un loading o skeleton hasta que el title esté definido
  const title = useMemo(() => {
    const setter = (value: string) =>
      threeDotsText(value.replaceAll("-", " "), 30);
    const { processName, projectName, storeName } = params;
    return setter(processName || projectName || storeName || "My Stores");
  }, [params]);

  // refresh & get info
  useEffect(() => {
    let store;
    if (params.storeId) {
      store = stores?.find((s) => s.id.toString() === params.storeId);
    }

    dispatch(setSelectedStore(store || null));
    if (params.projectId) {
      const project = projects?.find(
        (p) => p.id.toString() === params.projectId,
      );
      dispatch(setSelectedProject(project || null));
    }

    if (params.processId) {
      const process = processes?.find(
        (p) => p.process.id.toString() === params.processId,
      );

      dispatch(setSelectedProcess(process?.process || null));
      dispatch(setProjectProcessId(process?.id || null));
      dispatch(setProjectProcessSteps(process?.project_process_steps || null));
    }
  }, [dispatch, stores, projects, processes, params]);

  // when store name changes
  useEffect(() => {
    if (!params.storeId || !stores || params.projectId) return;
    const store = stores?.find((p) => p.id.toString() === params.storeId);

    if (store?.name !== params.storeName) {
      const route = `/stores/${(store?.name || "").replaceAll(" ", "-")}-${store?.id}`;
      navigate(route);
    }
  }, [stores]);

  // when project name changes
  useEffect(() => {
    if (!params.projectId || !projects || params.processId) return;

    const project = projects?.find((p) => p.id.toString() === params.projectId);
    const projectName = project?.name || project?.code;

    if (projectName !== params.projectName) {
      const newPathname = location.pathname
        .split("/")
        .slice(0, location.pathname.split("/").length - 1)
        .join("/");
      const route = `${newPathname}/${(project?.name || project?.code || "").replaceAll(" ", "-")}-${project?.id}`;
      navigate(route);
    }
  }, [projects]);

  // when process name changes
  useEffect(() => {
    if (!params.processId || !processes) return;
    const process = processes?.find(
      (p) => p.process.id.toString() === params.processId,
    );

    if (process?.process.name !== params?.processName) {
      const newPathname = location.pathname
        .split("/")
        .slice(0, location.pathname.split("/").length - 1)
        .join("/");
      const route = `${newPathname}/${process?.process.name.replaceAll(" ", "-")}-${process?.process.id}`;
      navigate(process ? route : newPathname);
    }
  }, [processes]);

  return (
    <div className="o-screen-container">
      <HeaderModule
        title={title}
        storeOwnerDetail={
          selectedStore ? selectedStore.owner_user?.name || "PENDING" : ""
        }
        goBack={!!selectedStore}
        withBreadcrumb // provisorio
      />
      <Outlet context={context} />
    </div>
  );
};

export default StoresLayout;
