import React from "react";

import InformationData from "./InformationData";
import useAppSelector from "@hooks/useAppSelector";

const Information: React.FC = () => {
  const { selectedStore } = useAppSelector((state) => state.myStores);

  const {
    name,
    store_number: storeNumber,
    owner_user: ownerUser,
    country,
    zip_code: zipCode,
    address_1: address1,
    address_2: address2,
    capacity,
    store_type: storeType,
    users,
    state,
    site_number: siteNumber,
  } = selectedStore!;

  return (
    <div className="c-store-information w-100">
      <div className="d-md-flex gap-3">
        <InformationData title="Store Name" data={name} />
        <InformationData title="Store Type" data={storeType.name} />
      </div>
      <div className="d-md-flex gap-3">
        <InformationData title="Site ID" data={siteNumber} />
        <InformationData title="Store Number" data={storeNumber} />
      </div>
      <div className="d-md-flex c-store-information__section">
        <InformationData
          title="Owner User"
          data={ownerUser?.name || "PENDING"}
        />
      </div>
      <InformationData title="Related Users" dataTags relatedUsers={users} />
      <div className="d-md-flex c-store-information__section">
        <InformationData title="Capacity" data={capacity} />
      </div>
      <div className="d-md-flex gap-3">
        <InformationData title="Address 1" data={address1} />
        <InformationData title="Address 2" data={address2} />
        <InformationData title="Zip Code" data={zipCode} />
      </div>
      <div className="d-md-flex gap-3">
        <InformationData title="Country" data={country.name} />
        <InformationData title="State" data={state?.name} />
      </div>
    </div>
  );
};

export default Information;
